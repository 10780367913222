import React from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReadMoreButton from '../ReadMoreButton'

export default function OneCol(props) {
  const { linkTo, width, text, readMoreHide, image, noMargin } = props.content

  const HasLink = ({ linkTo, children }) => {
    if (linkTo.length) return <Link to={linkTo[0].uri}>{children}</Link>
    return <>{children}</>
  }

  return (
    <section
      css={[tw`relative`, width === 'contained' && tw`container md:px-10 px-6`]}
      className={`oneCol ${width === 'space' && 'mm-mx-dynamic'} ${
        readMoreHide && 'readMoreHide'
      }`}
    >
      <HasLink linkTo={linkTo}>
        <div
          className={!noMargin && `mm-py-dynamic-content`}
          css={tw`max-w-4xl`}
        >
          {image[0]?.localFile?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              image={image[0]?.localFile?.childImageSharp?.gatsbyImageData}
              alt={image[0]?.title}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </HasLink>
      <ReadMoreButton text="Læs mere" />
    </section>
  )
}
